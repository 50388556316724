import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-plus/dist/index.css'
import '../src/assets/iconfont/iconfont.css'
import '../src/assets/style/common.css'
/* 引入 ElementPlus */
import '@/assets/style/index.styl'

import ElementPlus, { ElLoading } from 'element-plus';
import locale from "element-plus/es/locale/lang/zh-cn";
import Axios from 'axios'
const app = createApp(App)
    // axios 请求拦截，给所有接口的headers添加token（登录除外）
Axios.interceptors.request.use(config => {
    // console.log(config.url, 'configxxxxxxxx');
    if (config.url == "/api/member/login") {
        return config;
    } else if (config.url == "/api/member/register") {
        return config;
    } else if (config.url == "/api/sms/checkVerificationCode") {
        return config;
    } else if (config.url == "/api/sms/send") {
        return config;
    } else if (config.url == "/api/sms/checkVerificationCode") {
        return config;
    } else if (config.url == "/api/member/resetPassword") {
        return config;
    } else {
        config.headers.Authorization = 'Bearer ' + store.state.token
        return config;
    }

    // else if (config.url == "/api/user/loginByToken") {
    //     config.headers.Authorization = 'Bearer'
    //     return config;
    // } 
})
Axios.interceptors.response.use(config => {
    // console.log(config, 'config');
    if (config.data.status == -3) {
        router.push({
            name: 'Login'
        });
    }
    return config;
})
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store).use(router).use(ElementPlus, { locale }).mount('#app')
app.config.globalProperties.$ElLoading = ElLoading