// sessionStorage key
export const LOGIN_ID = 'LOGIN_ID'
export const LOGIN_STATE = 'LOGIN_STATE' // 登录状态
export const USER_NAME = 'USER_NAME' // 登录用户名
export const NAME = 'NAME' // 供应商名称
export const TENANTID = 'TENANTID' // 角色id
export const TOKEN = 'token' // token
export const ISADMIN = 'isAdmin' // isAdmin
export const MEMBERSHIP = 'membership' // 系统版本
export const KFWECHATURL = 'kfWechatUrl' // 客服
export const WXOPENId = 'wxOpenId' // wxopenid
export const ISLOGIN = 'isLogin' // wxopenid
export const AVATARIMG = 'avatarImg'
export const UNIONSTATUS = 'unionStatus'

// localStorage key
export const USER_NAME_LOGIN = 'USER_NAME_LOGIN' // 登录用户名
export const USER_PASSWORD = 'USER_PASSWORD'