import * as Mutations from "./mutation-types";


export default {

    /**
     * 设置用登录状态
     * @param {Boolean} isLogin
     */
    [Mutations.SET_LOGIN_STATE](state, isLogin) {
        state.isLogin = isLogin
        console.log('isLogin = ', isLogin);
    },


    [Mutations.SET_CART_TOTAL](state, catTotal) {
        state.catTotal = catTotal
    },

    [Mutations.SET_COUNTS](state, counts) {
        state.counts = counts
        console.log('counts = ', counts);
    },


    /**
     * 设置登录信息
     */
    [Mutations.SET_LOGIN_INFO](state, { id, name, tenantId, token, kfWechatUrl, userName, wxOpenId, membership, isLogin }) {
        state.id = id
        state.name = name
        state.userName = userName
        state.tenantId = tenantId
        state.token = token
        state.kfWechatUrl = kfWechatUrl
        state.wxOpenId = wxOpenId
        state.membership = membership
        state.isLogin = isLogin
        console.log('id = ', id);
        console.log('name = ', name);
        console.log('userName = ', userName);
        console.log('tenantId = ', tenantId);
        console.log('token = ', token);
        console.log('kfWechatUrl = ', kfWechatUrl);
        console.log('wxOpenId = ', wxOpenId);
        console.log('membership = ', membership);
        console.log('isLogin = ', isLogin);
    },

    [Mutations.SET_INFO_DETITAL](state, { userName, avatarImg, unionStatus }) {
        state.userName = userName
        state.avatarImg = avatarImg
        state.unionStatus = unionStatus
        console.log('userName = ', userName);
        console.log('avatarImg = ', avatarImg);
        console.log('unionStatus = ', unionStatus);
    }
}