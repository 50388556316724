const SESSION_STORAGE = 'sessionStorage'
const LOCAL_STORAGE = 'localStorage'


/**
 * 设置前端缓存
 * @param {String} type 前端缓存类型 sessionStorage localStorage
 * @param {String} key
 * @param {Any} value
 */
function setCache(type, key, value) {

    const jsonString = JSON.stringify(value)

    if (type === SESSION_STORAGE) {
        sessionStorage.setItem(key, jsonString)
    }

    if (type === LOCAL_STORAGE) {
        localStorage.setItem(key, jsonString)
    }
}

/**
 * 获取前端缓存
 * @param {String} type 前端缓存类型 sessionStorage localStorage
 * @param {*} key
 */
function getCache(type, key) {

    let result;

    if (type === SESSION_STORAGE) {
        result = sessionStorage.getItem(key)
    }

    if (type === LOCAL_STORAGE) {
        result = localStorage.getItem(key)
    }

    if (result === null) {
        return null
    } else {
        return JSON.parse(result)
    }

}

/**
 *
 * @param {String} 前端缓存类型 sessionStorage localStorage
 * @param {*} key
 */
function removeCache(type, key) {
    if (type === SESSION_STORAGE) {
        sessionStorage.removeItem(key)
    }

    if (type === LOCAL_STORAGE) {
        localStorage.removeItem(key)
    }
}

/**
 * 设置sessionStorage缓存
 * @param {String} key
 * @param {Any} value
 */
export function setSessionStorageCache(key, value) {
    return setCache(SESSION_STORAGE, key, value)
}

/**
 * 设置localStorage缓存
 * @param {String} key
 * @param {Any} value
 */
export function setLocalStorageCache(key, value) {
    return setCache(LOCAL_STORAGE, key, value)
}

/**
 * 获取sessionStorage缓存
 * @param {String} key
 */
export function getSessionStorageCache(key) {
    return getCache(SESSION_STORAGE, key)
}

/**
 * 获取localStorage缓存
 * @param {String} key
 * @param {Any} value
 */
export function getLocalStorageCache(key) {
    return getCache(LOCAL_STORAGE, key)
}

/**
 * 清除sessionStorage缓存
 * @param {String} key
 */
export function removeSessionStorageCache(key) {
    return removeCache(SESSION_STORAGE, key)
}

/**
 * 清除localStorage缓存
 * @param {String} key
 */
export function removeLocalStorageCache(key) {
    return removeCache(LOCAL_STORAGE, key)
}