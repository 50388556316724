<script setup>
import { onMounted } from "vue";
import { SET_LOGIN_INFO } from "@/store/mutation-types";
import { getLocalStorageCache } from "@/utility/cache";
import {
    LOGIN_ID,
    USER_NAME,
    NAME,
    TENANTID,
    TOKEN,
    MEMBERSHIP,
    WXOPENId,
    ISLOGIN,
} from "@/common/cache-key";
import { useStore } from "vuex";
const store = useStore();
// 强制刷新重新存缓存获取数据
const reloadData = () => {
    const id = getLocalStorageCache(LOGIN_ID);
    const userName = getLocalStorageCache(USER_NAME);
    const name = getLocalStorageCache(NAME);
    const token = getLocalStorageCache(TOKEN);
    const tenantId = getLocalStorageCache(TENANTID);
    const wxOpenId = getLocalStorageCache(WXOPENId);
    const membership = getLocalStorageCache(MEMBERSHIP);
    const isLogin = getLocalStorageCache(ISLOGIN);
    store.commit(SET_LOGIN_INFO, {
        id,
        userName,
        name,
        tenantId,
        token,
        membership,
        wxOpenId,
        isLogin
    });
};
onMounted(() => {
    reloadData();
});
</script>

<template>
    <router-view />
</template>


<style scoped lang="stylus"></style>
