import { createRouter, createWebHistory } from 'vue-router'
const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Login.vue')
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Signup.vue')
    },
    {
        path: '/Forgotpassword',
        name: 'Forgotpassword',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Forgotpassword.vue')
    },
    {
        path: '/Setpassword',
        name: 'Setpassword',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Setpassword.vue')
    },

    {
        path: '/',
        name: 'Home',
        component: () =>
            import ( /* webpackChunkName: "about" */ '@/views/Home.vue'),
        children: [{
                path: '/dashboard',
                name: 'Dashboard',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Dashboard/index.vue'),
            },
            {
                path: '/sellmember',
                name: 'SellMember',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Menbership/sellmember.vue'),
            },
            {
                path: '/members',
                name: 'Members',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Menbership/index.vue'),
            },
            {
                path: '/members/detail',
                name: 'MenbershipDetail',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Menbership/detail.vue')
            }, {
                path: '/patent',
                name: 'PatentLibrary',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/PatentLibrary/index.vue')
            },
            // {
            //     path: '/products/detail',
            //     name: 'SelectionLibraryDetail',
            //     component: () =>
            //         import ('@/views/SelectionLibrary/detail.vue')
            // },
            {
                path: '/myLeague',
                name: 'MyLeague',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/MyLeague/index.vue')
            },
            {
                path: '/my-information',
                name: 'my-information',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/MyInformation/index.vue')
            },
            {
                path: '/set-commission',
                name: 'set-commission',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/MyPatent/index.vue')
            },

            {
                path: '/my-partners',
                name: 'my-frend',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Myfrend/index.vue')
            },
            {
                path: '/my-sell',
                name: 'my-sell',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/MySell/index.vue')
            },
            {
                path: '/inquiries',
                name: 'Inquiries',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Inquiries/index.vue')
            },
            {
                path: '/sell-patent',
                name: 'Mysellpatent',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Mysellpatent/index.vue')
            },
            {
                path: '/business-opportunity',
                name: 'MybusinessOpportunity',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/MybusinessOpportunity/index.vue')
            },
            {
                path: '/quotation',
                name: 'MyQuotation',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/MyQuotation/index.vue'),
            },
            {
                path: '/quotation/detail',
                name: 'my-quotationdetail',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/MyQuotation/QuotationDetail.vue'),
            },
            {
                path: '/apply',
                name: 'Apply',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Apply.vue')
            },
            {
                path: '/basic-info',
                name: 'basic-info',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Basicinfo/Basicinfo.vue')
            },
            {
                path: '/membership',
                name: 'Membership',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Vip/Membership.vue')
            },
            {
                path: '/pay',
                name: 'Pay',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Vip/Pay.vue')
            },
            {
                path: '/annualfeemonitoring',
                name: 'Annualfeemonitoring',
                component: () =>
                    import ( /* webpackChunkName: "about" */ '@/views/Annualfeemonitoring/Annualfeemonitoring.vue')
            },
        ]
    }

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router